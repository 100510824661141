@import '@styles/sass/mixins/rem';

.textBox {
  display: block;
  &__text {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    span {
      font-size: rem(16);
      line-height: rem(22);
      font-weight: normal;
    }

    ul {
      margin: 0;
      padding: 0;
    }
  }

  &__title {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    span {
      font-size: rem(18);
      line-height: rem(25);
      font-weight: bold;
    }
  }
}
